<template>
  <div class="main">
    <div class="box justify center mb48">
      <div class="search box center">
        <el-input v-model="searchTxt" placeholder="请输入检查名称" suffix-icon="el-icon-search"></el-input>
        <el-button @click="searchData">查询</el-button>
        <el-button @click="resetData">重置</el-button>
      </div>
      <el-button @click="showWin" :class="!myUpload ? 'disable_icon' : ''">
        <span class="iconfont iconjiahao"></span>
        <span>上传</span>
      </el-button>
      <!-- <el-button @click="dialogVisible=true"><span class="iconfont iconjiahao"></span><span>上传资料</span></el-button> -->
    </div>
    <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="fileName" label="定期检查报告"></el-table-column>
      <el-table-column align="center" prop="timeW" label="检查时间"></el-table-column>
      <el-table-column align="center" prop="unit" label="定期检查单位"></el-table-column>
      <el-table-column align="center" prop="score" label="总分"></el-table-column>
      <el-table-column align="center" prop="sbci" label="下部结构SBCI"></el-table-column>
      <el-table-column align="center" prop="spci" label="上部结构SPCI"></el-table-column>
      <el-table-column align="center" prop="bdci" label="桥面系BDCI"></el-table-column>
      <!-- <el-table-column align="center" prop="timeO" label="上传时间">
        <template slot-scope="scope">{{ scope.row.timeO }}</template>
      </el-table-column>-->
      <el-table-column align="center" width="180px" label-class-name="option-btn" prop="id" label="操作">
        <template slot-scope="scope">
          <!-- <svg @click="downFile(scope.row)" class="icon fs22 operate-txt" aria-hidden="true">
              <use xlink:href="#iconxiazai"></use>
            </svg>
            <svg @click="deleteFile(scope.row)" class="icon fs22 ml24 delete-txt" aria-hidden="true">
              <use xlink:href="#iconshanchu"></use>
          </svg>-->
          <div class="btn-wrapper">
            <span type="text" class="colorText mR20 pointer" size="small" @click="downFile(scope.row)" :class="!myOption ? 'disable_icon' : ''">下载</span>
            <!-- <span type="text" class="colorText mR20 pointer" size="small" @click="check(scope.row)" v-if="scope.row.type !== '.docx' && scope.row.type !== '.doc' && scope.row.type !== '.zip'">查看</span> -->
            <span type="text" class="colorText mR20 pointer" size="small" @click="check(scope.row)" :class="!myOption ? 'disable_icon' : ''">{{ !affixArr.includes(scope.row.fileName.substring(scope.row.fileName.lastIndexOf("."))) ? "查看" : "" }}</span>
            <span type="text" class="colorText pointer" size="small" @click="deleteFile(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</span>
          </div>
        </template>
      </el-table-column>
      <template slot="empty" class="imgBox">
        <div>
          <img src="../../../assets/images/noData.png" alt class="imgver" />
          <span>暂无数据</span>
        </div>
      </template>
    </el-table>
    <common-pagination v-if="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" />
    <el-dialog title="上传" :visible.sync="dialogVisible" width="610px" :before-close="handleClose" top="150px" class="regular-ins">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="164px">
        <el-form-item label="名称" prop="title">
          <el-input placeholder disabled v-model="ruleForm.title" />
        </el-form-item>
        <!-- <el-form-item label="检查时间" prop="title">
          <el-input placeholder="请输入名称" v-model="ruleForm.title" />
        </el-form-item>-->
        <el-form-item prop="timeO" label="检查时间">
          <el-date-picker placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.timeO"></el-date-picker>
        </el-form-item>
        <el-form-item label="检查单位" prop="unit">
          <el-input placeholder="请输入" v-model="ruleForm.unit" />
        </el-form-item>
        <el-form-item label="下部结构分数" prop="sbci">
          <el-input placeholder="请输入" v-model="ruleForm.sbci" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="下部结构权重" prop="sbciFactor">
          <el-input placeholder="请输入" v-model="ruleForm.sbciFactor" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="上部结构分数" prop="spci">
          <el-input placeholder="请输入" v-model="ruleForm.spci" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="上部结构权重" prop="spciFactor">
          <el-input placeholder="请输入" v-model="ruleForm.spciFactor" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="桥面系数分数" prop="bdci">
          <el-input placeholder="请输入" v-model="ruleForm.bdci" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="桥面系数权重" prop="bdciFactor">
          <el-input placeholder="请输入" v-model="ruleForm.bdciFactor" @change="scoreCalc()" />
        </el-form-item>
        <el-form-item label="总分" prop="score">
          <el-input disabled v-model="ruleForm.score" />
        </el-form-item>
        <el-form-item label="文件" prop="file">
          <!-- :on-success="successData"  :auto-upload="false"-->
          <el-upload action=" " multiple :limit="1" :file-list="fileListAdd" :on-remove="handleAddRemove" :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
            <!-- <span slot="tip" class="el-upload__tip" style="font-size: 12px">上传文件不超过50M</span> -->
          </el-upload>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="back-btn pointer" @click="back">取消</el-button>
          <el-button class="submit-btn mR30 pointer" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="查看文件" :visible.sync="pdfVisible" width="1200px" :before-close="handlePdfClose" custom-class="pdf-class">
      <div class="pdf-wrapper">
        <div class="pdf-title" v-show="!downImgUrl">
          <el-button size="small" type="primary" @click="nextPdfPage('prev')">上一页</el-button>
          {{ currentPdfPage }} / {{ pagePdfCount }}
          <el-button size="small" type="primary" @click="nextPdfPage('next')">下一页</el-button>
        </div>

        <pdf v-show="!downImgUrl" :src="pdfUrl" @num-pages="pagePdfCount = $event" @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf>
        <img v-show="downImgUrl" :src="downImgUrl" alt class="down-img" />
        <div class="pdf-btm">
          <span class="sureBtn back-btn pointer mR30" @click="handlePdfClose">关闭</span>
          <!-- <span class="backBtn submit-btn pointer" @click="submitForm">确定</span> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
import pdf from "vue-pdf";
export default {
  name: "dataManagementList",
  components: {
    CommonPagination,
    pdf
  },

  data() {
    const fileMustUpload = (rule, value, callback) => {
      console.log(rule, value, callback, this.fileListAdd);
      if (!this.fileListAdd.length) {
        callback("请上传文件");
      }
      callback();
    };

    return {
      affixArr: [".zip", ".docx", ".doc"],
      isSubmit: true,
      pagePdfCount: 0,
      currentPdfPage: 1,
      downImgUrl: "",
      numPages: undefined,
      pdfUrl: "",
      pdfVisible: false,
      isTrue: "",
      filename: "",
      arr: [],
      projectId: "",
      pageSize: 10, //
      currentPage: 0,
      total: 0,
      loading: false,
      searchTxt: "",
      dialogVisible: false,
      actionURL: "",
      fileList: [],
      ruleForm: {
        title: "",
        bdci: "",
        bdciFactor: "",
        projectId: "",
        sbci: "",
        sbciFactor: "",
        score: "",
        spci: "",
        spciFactor: "",
        timeO: "",
        unit: "",
        file: ""
      },
      rules: {
        timeO: [{ required: true, message: "请选择时间", trigger: "change" }],
        unit: [{ required: true, message: "请输入检查单位", trigger: "blur" }],
        bdci: [
          { required: true, message: "请输入桥面系分数", trigger: "blur" }
          //   { message: "桥面系分数必须为0-100数字", type: "number", pattern: /^(0|[1-9]\d|100)$/ }
        ],
        bdciFactor: [
          { required: true, message: "请输入桥面系权重", trigger: "blur" }
          //   { message: "桥面系权重必须为0-1数字", type: "number", min: 0, max: 1 }
        ],
        sbci: [
          { required: true, message: "请输入下部结构分数", trigger: "blur" }
          //   { message: "下部结构分数必须为0-100数字", type: "number", min: 0, max: 100 }
        ],
        sbciFactor: [
          { required: true, message: "请输入下部结构权重", trigger: "blur" }
          //   { message: "下部结构权重必须为0-1数字", type: "number", min: 0, max: 1 }
        ],
        spci: [
          { required: true, message: "请输入上部结构分数", trigger: "blur" }
          //   { message: "上部结构分数必须为0-100数字", type: "number", min: 0, max: 100 }
        ],
        spciFactor: [
          { required: true, message: "请输入上部结构权重", trigger: "blur" }
          //   { message: "上部结构权重必须为0-1数字", type: "number", min: 0, max: 1 }
        ],
        file: [
          {
            type: "array",
            required: true,
            validator: fileMustUpload,
            trigger: "change"
          }
        ]
      },
      tableData: [],
      fileListAdd: [],
      hideUploadAdd: false,
      loadingPage: null
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      this.type = to.query.type;
      console.log(this.type);
    }
  },
  mounted() {
    this.actionURL = ``;
    this.type = this.$route.query.type;
    console.log(this.type);

    this.datalList(); //桥梁列表
    this.isTrue = sessionStorage.getItem("isTrue");
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },
  methods: {
    scoreCalc() {
      console.log("ruleform", this.ruleForm);
      if (this.ruleForm.bdci !== "" && this.ruleForm.bdciFactor !== "" && this.ruleForm.sbci !== "" && this.ruleForm.sbciFactor !== "" && this.ruleForm.spci !== "" && this.ruleForm.spciFactor !== "") {
        if (
          this.ruleForm.bdci < 0 ||
          this.ruleForm.bdci > 100 ||
          this.ruleForm.bdciFactor < 0 ||
          this.ruleForm.bdciFactor > 1 ||
          this.ruleForm.sbci < 0 ||
          this.ruleForm.sbci > 100 ||
          this.ruleForm.sbciFactor < 0 ||
          (this.ruleForm.sbciFactor > 1 && this.ruleForm.spci < 0) ||
          this.ruleForm.spci > 100 ||
          this.ruleForm.spciFactor < 0 ||
          this.ruleForm.spciFactor > 1
        ) {
          this.$message.error("分数范围0-100，权重范围0-1");
          this.isSubmit = true;
          return;
        }

        let arr = ["bdci", "bdciFactor", "sbci", "sbciFactor", "spci", "spciFactor"];
        for (let i = 0; i < arr.length; i++) {
          this.ruleForm[arr[i]] = parseFloat(this.ruleForm[arr[i]]).toFixed(2);
        }

        console.log(this.ruleForm.bdciFactor + this.ruleForm.sbciFactor + this.ruleForm.spciFactor, this.ruleForm.bdciFactor, this.ruleForm.sbciFactor, this.ruleForm.spciFactor);

        if (this.ruleForm.bdciFactor * 100 + this.ruleForm.sbciFactor * 100 + this.ruleForm.spciFactor * 100 != 100) {
          this.$message.error("三个权重的和为1");
          this.isSubmit = true;
          return;
        }
        this.isSubmit = false;
        this.ruleForm.score = ((this.ruleForm.bdci * this.ruleForm.bdciFactor * 10000 + this.ruleForm.sbci * this.ruleForm.sbciFactor * 10000 + this.ruleForm.spci * this.ruleForm.spciFactor * 10000) / 10000).toFixed(2);
      } else {
        // this.$message.error("权重和分数的值未填写完整");
      }
    },
    nextPdfPage(flag) {
      switch (flag) {
        case "next":
          if (this.currentPdfPage >= this.pagePdfCount) {
            this.currentPdfPage = this.pagePdfCount;
            return;
          }
          this.currentPdfPage++;
          break;
        case "prev":
          if (this.currentPdfPage <= 1) {
            this.currentPdfPage = 1;
            return;
          }
          this.currentPdfPage--;
      }
    },
    handlePdfClose() {
      this.pdfVisible = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    showWin() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      // if (!this.isTrue) return;
      this.dialogVisible = true;
      this.hideUploadAdd = false;
    },

    //返回
    back() {
      this.handleClose();
    },

    //查询
    searchData() {
      this.currentPage = 1;
      this.datalList();
    },

    //桥梁列表
    datalList() {
      this.$axios
        .get(`${this.baseURL}health/regular/page/${this.projectId}?name=${this.searchTxt}&pageNum=${this.currentPage}&pageSize=${this.pageSize}`)
        .then((res) => {
          console.log("资料列表", res);
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
          // if(this.tableData.length == 0){
          //   if( this.currentPage >1){
          //      this.currentPage =  this.currentPage -1;
          //      this.datalList();
          //   }else{
          //     this.currentPage = 1;
          //      this.datalList();
          //   }
          // }
        })
        .catch((err) => {});
    },

    // 上传change事件
    handleAddChange(file, fileList) {
      console.log("file", file, "filelist", fileList);

      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了!");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = this.fileListAdd;
        this.ruleForm.title = file.name;
        this.$refs.ruleForm.validate((valid) => {});
      }
      if (fileList.length == 0) {
        this.ruleForm.title = "";
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 1) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },

    // 确定新增
    submitForm() {
      console.log("this.isSubmit", this.isSubmit);
      //   this.ruleForm.file_arr = this.fileListAdd;
      if (this.ruleForm.bdci === "" || this.ruleForm.bdciFactor === "" || this.ruleForm.sbci === "" || this.ruleForm.sbciFactor === "" || this.ruleForm.spci === "" || this.ruleForm.spciFactor === "") {
        this.$message.error("权重和分数未填写完整！");
        return;
      }
      if (this.isSubmit) {
        // this.$message.error("数值填写不符合要求，请重新填写!");
        this.scoreCalc();
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          this.ruleForm.projectId = this.projectId;

          let formData = new FormData();
          for (let k in this.ruleForm) {
            formData.append(k, this.ruleForm[k]);
          }

          this.fileListAdd.map((item) => {
            formData.append("file", item.raw);
          });
          this.ruleForm.file = formData;
          this.$axios.post(`${this.baseURL}health/periodic/import`, formData).then((res) => {
            console.log("上传接口===========", res);
            this.loadingPage.close();
            this.handleClose();
            if (res.data.errCode == "200") {
              this.$message({
                message: "上传成功",
                type: "success"
              });
            } else {
              this.$message.error("上传失败!");
            }
          });
        }
      });
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      this.dialogVisible = false;
      this.datalList();
    },

    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.datalList();
    },

    //是否删除
    deleteFile(data) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }

      console.log(data);
      this.$confirm(`<i class="el-icon-warning"></i>确认删除"${data.fileName}"？`, "确认删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          this.httpDel(data.fileId);
        })
        .catch(function () {});
    },

    httpDel(id) {
      this.$axios.delete(`${this.baseURL}health/periodic/del/${id}`).then((res) => {
        console.log("删除", res);

        if (res.data.errCode === "200") {
          this.$message({
            // message: res.data.errMsg,
            message: "删除成功！",
            type: "success",
            duration: 1000
          });
          this.currentPage = 1;
          this.datalList();
        } else {
          this.$message.error("删除失败!");
        }

        // if (res.data.code === 1) this.getDataList()
      });
    },

    //重置
    resetData() {
      this.handleCurrentChange(1);
      this.currentPage = 1;
      this.searchTxt = "";
      this.datalList();
    },

    // 下载
    downFile(item) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      var filename = item.fileName;
      var id = item.fileId;
      //  let a = document.createElement('a')
      // a.href = `${this.baseURL}sys/sysFile/${id}`;
      // a.click();

      //  this.$axios.get(`${this.baseURL}sys/sysFile/${id}`).then(res => {
      //     console.log('下载',res)
      // })
      // bridge.cloudansys.cn   this.baseURL
      // var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)

      // 老版本
      // var a = document.createElement('a')
      // a.href = `${this.downloadURL}sys/sysFile/${id}`
      // const agent = window.navigator
      // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      //   // a.target = '_blank'
      // }
      // a.click()

      let url = `${this.downloadURL}sys/sysFile/${id}`;
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = filename; // 文件名
      document.body.appendChild(link);
      link.click();
      console.log("filename", filename);
      document.body.removeChild(link); // 下载完成移除元素

      // link.download = item.fileName;
      // link.href = e.target.result;
      // document.body.appendChild(a);
      // link.click();
    },
    check(row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("ropw====", row);
      let arr = [".png", ".jpg", ".jpeg", ".svg"];
      if (arr.includes(row.type)) {
        this.pdfVisible = true;
        this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.fileId}`;
        console.log("downImgUrl", this.downImgUrl);
        return;
      }
      // if (row.type == ".docx" || row.type == ".doc") {
      //   var a = document.createElement("a");
      //   a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   a.click();
      //   return;
      // }

      this.pdfVisible = true;
      //  var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)
      // var a = document.createElement("a");
      this.downImgUrl = "";
      this.currentPdfPage = 1;
      this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.fileId}`);
      // this.pdfUrl.promise.then((pdf) => {
      //   console.log("numPages", this.numPages);
      //   this.numPages = pdf.numPages;
      // });
      console.log("pafurl", this.pdfUrl);
      // a.href = `${this.downloadURL}sys/sysFile/${id}`;
      // const agent = window.navigator;
      // // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      // // }
      // a.click();
      // console.log(`${this.downloadURL}sys/sysFile/${id}`, PDFObject);
      // console.log("PDFObject", PDFObject);
      // PDFObject.embed(`../../../assets/pdf/a.pdf`, "#example");
    }
  }
};
</script>

<style scoped lang="scss">
.main ::v-deep .el-dialog__wrapper div.pdf-class {
  width: 1200px !important;
  margin-top: 0px !important;
  max-height: calc(100vh - 8px);
  overflow: auto;
  margin: auto;

  .pdf-wrapper {
    box-sizing: border-box;
    text-align: center;

    img {
      margin: auto;
    }

    .pdf-btm {
      padding-top: 20px;
      text-align: center;
    }

    .pdf-title {
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

.down-img {
  width: 100%;
}

.main {
  height: 100%;
  margin-top: 20px;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 48px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

.regular-ins {
  ::v-deep .el-form-item__label {
    font-size: 12px !important;
  }
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-form-item__content {
    font-size: 12px !important;
  }
  ::v-deep .el-input .el-input__inner {
    font-size: 12px !important;
  }
  ::v-deep ::placeholder {
    font-size: 12px !important;
  }
  button.el-button {
    font-size: 12px;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    border-color: rgba(151, 151, 151, 0.23);
  }
  ::v-deep .el-input {
    input::-webkit-input-placeholder,
    input::-moz-input-placeholder,
    input::-ms-input-placeholder,
    ::placeholder {
      font-size: 12px !important;
      color: red;
    }
  }
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}

::v-deep .el-table th.el-table__cell > .option-btn.cell {
  padding-right: 50px;
  box-sizing: border-box;
}

.submit-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

.back-btn {
  text-align: center;
  display: inline-block;
  // width: 100px;
  // height: 32px;
  //  line-height: 32px;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
